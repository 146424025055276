import { downloadFile } from '../file.platform';
import { createToast } from '../toasts.platform';
import { exportOPML } from './export';

export default async function exportOPMLToFile() {
  const xml = await exportOPML();
  if (xml) {
    downloadFile('Reader_Feeds.opml', xml, 'text/xml');
    createToast({
      content: 'Starting download of OPML file',
      category: 'success',
    });
  } else {
    createToast({
      content: 'No feeds to export',
      category: 'warning',
    });
  }
}
